<script>
//import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  ordersMethods,
  ordersGetters,
} from "@/state/helpers";
//import axios from 'axios';

/**
 * Orders Component
 */
export default {
  components: {
    // Layout,
    Layout: () => import('../../layouts/main'),
    PageHeader
  },
  data() {
    return {
      title: "Shipped Orders",
      items: [
        {
          text: "Orders",
          active: true
        }
      ],
      selected: 'first_name',
      submitted: false,
      showmodal: false,
      hideModal() {
        this.submitted = false;
        this.showmodal = false;
        this.contacts = {};
      },
      emailto: 'customer',
      email:'',
      orderId:0,
      options: [
        { item: 'first_name', name: 'Filter By First Name' },
        { item: 'last_name', name: 'Filter By Last Name' },
        { item: 'email', name: 'Filter By Email' },
        { item: 'order_id', name: 'Filter By Order ID' }
      ],
      sendToOther:false,
      currentPag: 1,
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      filter: '',
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "post_title", sortable: true, label: "Order ID" },
        { key: "date", sortable: true },
        { key: "first_name", sortable: true, label: "First Name" },
        { key: "last_name", sortable: true, label: "Last Name" },
        { key: "age_checked", sortable: true, label: "Age Checked" },
        { key: "status", sortable: true, label: "Status" },
        { key: "order_total", sortable: true, label: "Total" },
        { key: "action" }
      ]
    };
  },
  computed: {
    ...ordersGetters,
    orders() {
      return this.getOrders;
    },
  },
  created() {
    let page = this.currentPage;
    let per_page = this.perPage;
    let filter = this.filter;
    let searchBy = this.selected;
    this.setOrders({ page, per_page, filter, searchBy });
  },
  mounted() {
    this.currentPag = this.currentPage;
  },
  methods: {
    ...ordersMethods,
    handlePanigination(page) {
      let filter = this.filter;
      let per_page = this.perPage;
      let searchBy = this.selected;
      this.setOrders({ page, per_page, filter, searchBy });
    },
    handleUserPerPage() {
      let filter = this.filter;
      let per_page = this.perPage;
      let searchBy = this.selected;
      this.setOrders({ page: 1, per_page, filter, searchBy });
    },
    handleSearch() {
      let page = this.currentPage;
      let per_page = this.perPage;
      let filter = this.filter;
      let searchBy = this.selected;
      if (filter.length >= 3) {
        this.setOrders({ page, per_page, filter, searchBy });
      } else {
        this.setOrders({ page, per_page, filter: '', searchBy: '' });
      }
    },
    handleSubmit(){
      this.sendEmail({
        order_id:this.orderId,
        emailto:this.emailto,
        sendToOther:this.sendToOther,
        email:this.email
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card"></div>
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Orders</a>
                </template>

                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" @change="handleUserPerPage" size="sm"
                          :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search  -->
                  <div class="col-sm-12 col-md-4 offset-lg-2">
                    <div id="tickets-table_filter"
                      class="row no-gutters align-items-center dataTables_filter text-md-right">
                      <div class="col-4">
                        <label class="mb-0 mr-2">Search By:</label>
                      </div>
                      <div class="col-4">
                        <b-form-select v-model="selected" :options="options" class="py-0 px-2 select_search-type"
                          value-field="item" text-field="name" disabled-field="notEnabled"></b-form-select>
                      </div>
                      <div class="col-4">
                        <b-form-input v-model="filter" type="search" @input="handleSearch"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </div>
                    </div>
                  </div>
                  <!-- End search  -->
                </div>
                <div class="table-responsive">
                  <!-- :filter="filter" :filter-included-fields="filterOn" -->
                  <b-table class="table-centered" :busy="loader" :items="orders" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(post_title)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'canceled',
                        'badge-soft-success': `${row.value}` === 'shipped',
                        'badge-soft-warning': `${row.value}` === 'pending',
                        'badge-soft-info': `${row.value}` === 'processing'
                      }">{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(age_checked)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'unverified',
                        'badge-soft-success': `${row.value}` === 'verified',
                        'badge-soft-warning': `${row.value}` === 'not_attempt'
                      }">{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                      <a href="javascript:;" class="mr-3 text-success" @click="showmodal = true; orderId = row.item.ID;" v-b-tooltip.hover
                        title="Send Email">
                        <i class="mdi mdi-email-send font-size-18"></i>
                      </a>
                      <!-- <router-link class="mr-3 text-primary" v-b-tooltip.hover title="Edit" to="">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </router-link>
                      <router-link class="text-danger" v-b-tooltip.hover title="Delete" to="">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </router-link> -->
                    </template>
                    <!-- <template v-slot:cell(send_email)="row">
                      <b-button variant="primary" class="d-flex align-items-center" >Send  
                        <b-spinner type="grow" class="ml-2" small label="Loading..."></b-spinner>
                     </b-button>
                    </template> -->
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPag" :total-rows="totalOrdes" :per-page="perPage"
                          @change="handlePanigination($event)">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal id="modal-1" centered v-model="showmodal" title="Send Order Email" title-class="text-dark font-18" hide-footer>
      <form @submit.prevent="handleSubmit()">
        <b-form-group label="Send Email To" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3"
            value="customer">Email to Customer</b-form-radio>
          <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3" value="admin">Email to
            Admin</b-form-radio>
          <b-form-checkbox id="checkbox-1" v-model="sendToOther" name="checkbox-1" value="yes"
            unchecked-value="no">
            Send Email to Other
          </b-form-checkbox>
        </b-form-group>
        <div class="form-group" v-if="sendToOther == 'yes'">
          <label for="exampleInputEmail1">{{ (emailto == 'customer') ? 'Send Customer Email To' : 'Send Admin Email To' }}</label>
          <input id="email" v-model="email" type="username" name="username" class="form-control"
            placeholder="Enter email" />
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">Send</button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>

<style>
.select_search-type {
  height: 30px;
}
</style>